import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import "../styles/404.css"

import startone from "../images/start-one.svg"
import starttwo from "../images/start-two.svg"

const browser = typeof window !== "undefined" && window

const PageNotFound = ({ siteTitle }) => {
  return (
    browser && (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>404 | Position2</title>
          <body className="page-not-found" />
        </Helmet>
        <div className="page-not-found">
          <Layout>
            <section id="PageNotFound">
              <div className="container">
                <div className="container-width">
                  <div className="page-holder">
                    <div className="wrap">
                      <span className="text">4</span>
                      <span className="bubble-animation">
                        <strong>Oops!</strong>
                      </span>
                      <span className="text">4</span>
                    </div>
                    <div className="img-one img-ani">
                      <img src={startone} alt="Start" />
                    </div>
                    <div className="img-two img-ani">
                      <img src={starttwo} alt="Start" />
                    </div>
                  </div>
                  <p>
                    Sorry, we can’t find that page! It might be an old
                    <br /> link or maybe it moved.
                  </p>
                  <a href="/">Back to home</a>
                </div>
              </div>
            </section>
          </Layout>
        </div>
      </>
    )
  )
}

PageNotFound.propTypes = {
  siteTitle: PropTypes.string,
}

PageNotFound.defaultProps = {
  siteTitle: ``,
}

export default PageNotFound
